export enum AppRoute {
  main = '/',
  email = '/email',
  universityEmail = '/email/university',
  personalEmail = '/email/personal',
  startChat = '/start-chat',
  congratulations = '/congratulations',
  uploaded = '/uploaded',
  expired = '/expired',
  unsubscribe = '/unsubscribe',
  aboutUs = '/about-us',
  textToSpeech = '/text-to-speech',
  somethingWentWrong = '/something-went-wrong',
  avuser = '/avuser',
  videoPreview = '/videoPreview',
  login = '/login',
  studentLoginPage = '/student-login',
  studentNameListener = '/student-name-listener',
  studentNameListenerEdit = '/student-name-listener-edit',
  selfRegistration = '/self-registration',
}
