import {
  ConfirmParticipantCollectiveData,
  CreateFetchInstitutionData,
  CreateFetchInstitutionEventData,
  CreateFetchParticipantData,
  SendEmailForSubscripe,
} from './selfRegistration.types';

export const createFetchInstitutionData: CreateFetchInstitutionData = (eventId: string) => ({
  endpoint: `/participant-subscribe/${eventId}`,
  method: 'GET',
});

export const createFetchInstitutionEventData: CreateFetchInstitutionEventData = (instituitionId: string) => ({
  endpoint: `/participant-subscribe/${instituitionId}/events`,
  method: 'GET',
});

export const createParticipantOnboarding: CreateFetchParticipantData = (eventId: string) => body => ({
  endpoint: `/participant-subscribe/${eventId}/participant`,
  method: 'POST',
  body,
});

export const confirmParticipantApproveCollectiveUI: ConfirmParticipantCollectiveData = (
  participantId: string,
) => body => ({
  endpoint: `/participant-subscribe/allow-donation/${participantId}`,
  method: 'POST',
  body,
});

export const sendEmailForSubscripeParticipant: SendEmailForSubscripe = (participantId: string) => ({
  endpoint: `/participant-subscribe/send-email/${participantId}`,
  method: 'POST',
});
