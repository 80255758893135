import { makeStyles, styled } from '@material-ui/core';

import { Button } from 'shared/components';
import { GridItem } from 'shared/components/grid';

export const Left = styled(GridItem)(({ theme: { breakpoints } }) => ({
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [breakpoints.down('xs')]: {
    flexDirection: 'column',
  },
}));

export const Right = styled(GridItem)({
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ActionButton = styled(Button)(({ theme: { breakpoints } }) => ({
  width: '100px',
  marginBottom: '10px',
  [breakpoints.down('xs')]: {
    width: '210px',
    marginTop: '8px',
  },
}));

export const Buttons = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const useStyles = makeStyles(({ breakpoints }) => ({
  hidden: {
    display: 'none',
  },
  content: {
    display: 'flex',
    flex: 1,
    margin: 20,
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    '& [for="pronunciationName"]': {
      width: 480,
      marginLeft: -14,
      fontSize: 16,
      [breakpoints.only('md')]: {
        width: 410,
        fontSize: 15,
      },
      '& + div': {
        marginTop: 54,
      },
    },
  },
  audioButton: {
    [breakpoints.down('sm')]: {
      width: 210,
    },
  },
}));
