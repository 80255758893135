import { makeStyles } from '@material-ui/core';

const truncatedText = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
} as const;
export const useStyles = makeStyles(({ palette, spacing, breakpoints, typography: { fontWeightBold, h3 } }) => ({
  header: {
    fontSize: spacing(4.25),
    color: palette.black.main,
    fontWeight: spacing(87.5),
    fontFamily: 'Open Sans',
    width: 'auto',
    marginBottom: spacing(4),

    [breakpoints.only('sm')]: {
      fontSize: spacing(3),
      marginBottom: spacing(2),
    },

    [breakpoints.down('lg')]: {
      fontSize: h3.fontSize,
      lineHeight: h3.lineHeight,
    },
  },

  studentNameWrapper: {
    textAlign: 'center',
  },

  link: {
    fontWeight: fontWeightBold,
    display: 'inline-block',
    marginTop: spacing(1),
  },
  footerContainer: {
    '& footer': {
      position: 'relative',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  rootText: {
    // margin: spacing(3, 15.75, 0, 15.75),
    fontSize: spacing(4),
    fontWeight: spacing(87.5),
    fontFamily: 'Open Sans',
    textAlign: 'center',
    color: '#000000',
    marginTop: spacing(2),
    [breakpoints.down('sm')]: {
      fontSize: spacing(3),
      // margin: spacing(2),
    },
  },
  rootSubText: {
    fontSize: spacing(3),
    fontFamily: 'Open Sans',
    textAlign: 'center',
    color: '#000000',
    [breakpoints.down('sm')]: {
      fontSize: spacing(3),
      // margin: spacing(2),
    },
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rootTextSub: {
    fontSize: spacing(4.25),
    fontWeight: spacing(87.5),
    fontFamily: 'Open Sans',
    textAlign: 'center',
    [breakpoints.down('sm')]: {
      fontSize: spacing(2.5),
    },
  },
  logoImage: {
    marginTop: spacing(3),
    height: spacing(20),
    [breakpoints.up('md')]: {
      marginLeft: spacing(0),
    },
    [breakpoints.down('sm')]: {
      marginTop: spacing(1.25),
      marginLeft: spacing(0),
    },
    textAlign: 'center',
  },
  background: {
    position: 'relative',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: '96%',
    backgroundSize: '100%',
    backgroundImage: ({ background }: { background?: string }) => `url(${background}), ${palette.blue}`,
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // transform: 'translate(-50%, -50%)',
    width: spacing(70),
    maxWidth: '90%',
    overflowY: 'auto',
    // paddingTop: spacing(10),
  },
  card: {
    marginTop: spacing(2),
    background: palette.white.main,
    // borderRadius: spacing(1.5),
    padding: spacing(4),
    marginLeft: 'auto',
    // border: '1px solid black',
    maxHeight: '90vh',
    // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    // marginRight: 'auto',
    // textAlign: 'left',
    // padding: spacing(3, 7.25, 6, 7.25),
    // borderRadius: spacing(1.5),
    width: '100%',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacing(4),
    backgroundColor: palette.green.dark,
    color: palette.white.main,
    width: '100%',
    textTransform: 'capitalize',
    fontSize: spacing(3),
    '&:hover': {
      backgroundColor: palette.scorpionGray.dark,
    },
    '& .MuiButton-label': {
      fontSize: spacing(3),
      letterSpacing: 'normal',
      fontFamily: 'Open Sans',
      fontWeight: 700,
      lineHeight: '32.68px',
    },
  },
  buttonGreenContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: palette.gray.light,
    color: palette.green.dark,
    height: 'fit-content',
    textTransform: 'capitalize',
    width: '30%',
    fontSize: spacing(2),
    [breakpoints.down('sm')]: {
      width: '48%',
    },
    fontWeight: fontWeightBold,
  },
  bottomContainer: {
    fontSize: spacing(1.5),
  },
  mainContainer: {
    overflow: 'hidden',
    backgroundColor: '#F9F8F8',
    minHeight: '100vh',
    paddingBottom: spacing(2),
    [breakpoints.down('sm')]: {
      backgroundImage: 'none',
      backgroundColor: '#F9F8F8',
    },
  },
  finishButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20%',
    [breakpoints.down('sm')]: {
      // width: '100%',
      // position: 'sticky',
      // padding: spacing(0.5),
      width: '100%',
      position: 'fixed',
      bottom: 0,
      padding: spacing(0.5),
      zIndex: 1000,
    },
  },
  snackBar: {
    '& .MuiSnackbarContent-root': {
      backgroundColor: palette.red.light,
    },
  },
  copyLinkContainer: {
    width: '100%',
    padding: '6px 12px',
    // border: '1px solid #ccc',
    borderRadius: '4px',
    cursor: 'pointer',
    backgroundColor: '#F3F3F3',
    userSelect: 'none',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'space-between',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    ...truncatedText,
  },
  linkSpan: {
    color: '#18a957',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'capitalize',
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex',
  },
  copyLinkSpan: {
    color: '#18a957',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'capitalize',
    width: '15%',
  },
  addStudentLink: {
    ...truncatedText,
    width: '100%',
  },
  addStudentLinkSpan: {
    ...truncatedText,
    width: '100%',
    fontSize: spacing(2),
    color: '#1F2933',
    fontFamily: 'Open Sans',
    fontWeight: 400,
    lineHeight: '21.79px',
  },
  addStudentLinkSpanAmazon: {
    ...truncatedText,
    width: '100%',
    fontSize: spacing(1.375),
    color: '#1F2933',
    fontFamily: 'Open Sans',
    fontWeight: 400,
    lineHeight: '21.79px',
  },
  wooCommerceLink: {
    width: '85%',
    fontWeight: spacing(75),
  },
  copiedLinkSpan: {
    color: '#18a957',
    fontSize: spacing(2),
    width: '100%',
    fontWeight: 800,
    textTransform: 'capitalize',
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex',
    padding: '0px 8px',
    lineHeight: '21.79px',
  },
  socialIcon: {
    display: 'flex',
    width: '44px',
    height: '51px',
    marginTop: '13px',
    margin: spacing(1.6, 1.5, 0, 1.5),
    cursor: 'pointer',
    [breakpoints.down('sm')]: {
      // padding: '6px',
      margin: spacing(0.85, 0.75, 0, 0.875),
    },
  },
  socialIconHover: {
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#F3F3F3',
    },
    [breakpoints.down('sm')]: {
      // padding: spacing(1.5),
      // display: 'flex',
      // justifyContent: 'space-around',
      marginTop: '8px',
    },
  },
  socialIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '8px',
    // [breakpoints.down('xs')]: {
    //   // minWidth: '320px',

    // },
    flexDirection: 'column',

    '@media (max-width:375px)': {
      flexDirection: 'column',
    },
  },
  socialIconSubContainer: {
    display: 'flex',
    justifyContent: 'center',
    [breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
  },
  socialMediaIconButton: {
    [breakpoints.down('sm')]: {
      padding: '2px',
      marginTop: '8px',
      width: '39.74px',
      height: '46.3px',
    },
  },
}));
