/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-fetching-library';
import { Snackbar } from '@material-ui/core';
import queryString from 'query-string';

import { ConfirmCollectiveUIContainer } from './confirmCollectiveUI/ConfirmCollectiveUIContainer';
import { HighSchoolSelfRegisterContainer } from './selfRegistration/HighSchoolSelfRegisterContainer';
import { CompleteSelfRegistrationContainer } from './completeSelfRegistration/CompleteSelfRegistrationContainer';
import {
  ClipPurchaseTypes,
  InstitutionDataResponse,
  InstitutionEventDataResponse,
} from 'api/actions/selfRegistration/selfRegistration.types';
import {
  createFetchInstitutionData,
  createFetchInstitutionEventData,
} from 'api/actions/selfRegistration/selfRegistration.actions';
import { useStyles } from './HighSchoolSelfRegistration.styles';
import { SelectInstituitionContainer } from './selectInstituition/SelectInstituitionContainer';

export const SelfRegistrationContainer = () => {
  let isCollective;
  let instituteLogo;
  let isLoading = true;
  let instituitionEventDetails: InstitutionEventDataResponse | undefined;
  let isRetailtest = false;
  let isAllowForAmazonMusic;

  const { search } = useLocation();
  const styles = useStyles();

  const params = new URLSearchParams(search);
  const queryParams = queryString.parse(search);
  const queryParamKeys = Object.keys(queryParams);

  const eventId = params.get('eventId') ?? '';

  const instituitionId = params.get('instituitionId') ?? '';

  const [isProhibitedEmailModalOpen, setIsProhibitedEmailModalOpen] = useState<React.ReactNode | null>(null);

  // Check the step
  const [activeStep, setActiveStep] = useState(0);

  const [participantDetails, setParticipantDetails] = useState({
    id: '',
    wooCommercePermalink: '',
  });
  const [instituitionEventId, setInstituitionEventId] = useState('');
  const [isRetail, setIsRetail] = useState<boolean>(isRetailtest);

  if (queryParamKeys[0] === 'eventId') {
    const { payload, loading } = useQuery<InstitutionDataResponse>(createFetchInstitutionData(eventId));
    isLoading = loading;
    instituteLogo = payload?.institutionLogo;
    isCollective = payload?.isAllowCollctiv;
    isRetailtest =
      payload?.clipPurchaseType === null ? false : payload?.clipPurchaseType === ClipPurchaseTypes.RetailEndUserPays;
    isAllowForAmazonMusic = payload?.isAllowForAmazonMusic;
  } else if (queryParamKeys[0] === 'instituitionId') {
    const { payload: instituitionData, loading } = useQuery<InstitutionEventDataResponse>(
      createFetchInstitutionEventData(instituitionId),
    );
    isLoading = loading;
    instituteLogo = instituitionData?.institutionLogo;
    isCollective = instituitionData?.isAllowCollctiv;
    instituitionEventDetails = instituitionData;
    isAllowForAmazonMusic = instituitionData?.isAllowForAmazonMusic;
  }

  const handleSnackBarClose = () => {
    setIsProhibitedEmailModalOpen(null);
  };

  const isInstitution = queryParamKeys[0] === 'instituitionId';

  return (
    <>
      {isInstitution && activeStep === 0 && instituitionEventDetails && (
        <SelectInstituitionContainer
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          instituteLogo={instituteLogo}
          isCollective={isCollective}
          isLoading={isLoading}
          setIsRetail={setIsRetail}
          setInstituitionEventId={setInstituitionEventId}
          instituitionEventDetails={instituitionEventDetails}
        />
      )}
      {(isInstitution ? activeStep === 1 : activeStep === 0) && (
        <HighSchoolSelfRegisterContainer
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          eventId={instituitionEventId === '' ? eventId : instituitionEventId}
          setParticipantDetails={setParticipantDetails}
          isCollective={isCollective}
          isRetail={isRetail}
          instituteLogo={instituteLogo}
          setSnackbarMessage={(message: React.ReactNode) => setIsProhibitedEmailModalOpen(message)}
          queryParamKeys={queryParamKeys[0]}
          isAllowForAmazonMusic={isAllowForAmazonMusic}
        />
      )}
      {(isInstitution ? activeStep === 2 : activeStep === 1) &&
        ((isCollective && !isRetail) || (isAllowForAmazonMusic && !isRetail)) && (
          <ConfirmCollectiveUIContainer
            activeStep={activeStep}
            participantId={participantDetails.id}
            setActiveStep={setActiveStep}
            instituteLogo={instituteLogo}
            isCollective={isCollective}
            isRetail={isRetail}
            queryParamKeys={queryParamKeys[0]}
            isAllowForAmazonMusic={isAllowForAmazonMusic}
          />
        )}
      {(isInstitution ? activeStep === 3 : activeStep === 2) && (
        <CompleteSelfRegistrationContainer
          activeStep={activeStep}
          participantWooCommercePermalink={participantDetails.wooCommercePermalink}
          setActiveStep={setActiveStep}
          instituteLogo={instituteLogo}
          isCollective={isCollective}
          isRetail={isRetail}
          queryParamKeys={queryParamKeys[0]}
          isAllowForAmazonMusic={isAllowForAmazonMusic}
        />
      )}

      <Snackbar
        open={!!isProhibitedEmailModalOpen}
        onClose={handleSnackBarClose}
        message={isProhibitedEmailModalOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        className={styles.snackBar}
        autoHideDuration={10000}
      />
    </>
  );
};
