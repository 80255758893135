import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Root } from '../root/Root';
import { Congratulations } from '../congratulations/Congratulations';
import { Uploaded } from '../uploaded/Uploaded';
import { Expired } from '../expired/Expired';
import { Error } from '../error/Error';
import { Email } from '../email/Email';
import { Unsubscribe } from '../unsubscribe/Unsubscribe';
import { AboutUs } from '../aboutUs/AboutUs';
import { TextToSpeechContainer } from '../textToSpeech/TextToSpeechContainer';

import { AvUserRoot } from 'app/avUserRoot/AVUserRoot';
import { VideoPreview } from 'app/videoPreview/VideoPreview';
import { EmailTemplate } from 'shared/templates/email/EmailTemplate';
import { MainTemplate } from 'shared/templates/main/MainTemplate';
import { AuthorizedRoute } from 'shared/components/authorizedRoute/AuthorizedRoute';
import { AVUserAuthorizedRoute } from 'shared/components/avUserAuthorizedRoute/AVUserAuthorizedRoute';
import { AppRoute } from './AppRoute.enum';
import { InstitutionEmailContainer } from 'app/email/institutionEmail/InstitutionEmailContainer';
import { StartChat } from 'app/email/startChat/StartChat';
import { PersonalEmailContainer } from 'app/email/personalEmail/PersonalEmailContainer';
// import { StudentLoginForm } from 'app/studentLoginPage/studentLoginForm/studentLoginForm';
import { StudentLoginPage } from 'app/studentLoginPage/studentLoginPage';
// import { StudentNameListener } from 'app/studentNameListener/StudentNameListener';
import { StudentNameListenerEdit } from 'app/studentNameListener/studentNameListenerEdit/StudentNameListenerEdit';
import { SelfRegistrationContainer } from 'app/highSchoolSelfRegistration/HighSchoolSelfRegistrationContainer';

export const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path={[AppRoute.email, AppRoute.universityEmail, AppRoute.personalEmail, AppRoute.startChat]}>
        <EmailTemplate>
          <Route exact path={AppRoute.email} component={Email} />
          <Route exact path={AppRoute.universityEmail} component={InstitutionEmailContainer} />
          <Route exact path={AppRoute.personalEmail} component={PersonalEmailContainer} />
          <Route exact path={AppRoute.startChat} component={StartChat} />
        </EmailTemplate>
      </Route>

      <Route exact path={[AppRoute.main]}>
        <MainTemplate>
          <AuthorizedRoute path={AppRoute.main} exact component={Root} />
        </MainTemplate>
      </Route>

      <Route
        exact
        path={[
          AppRoute.congratulations,
          AppRoute.uploaded,
          AppRoute.expired,
          AppRoute.somethingWentWrong,
          AppRoute.unsubscribe,
          AppRoute.aboutUs,
          AppRoute.textToSpeech,
        ]}
      >
        <MainTemplate>
          <Route exact path={AppRoute.congratulations} component={Congratulations} />
          <Route exact path={AppRoute.uploaded} component={Uploaded} />
          <Route exact path={AppRoute.expired} component={Expired} />
          <Route exact path={AppRoute.somethingWentWrong} component={Error} />
          <Route exact path={AppRoute.unsubscribe} component={Unsubscribe} />
          <Route exact path={AppRoute.aboutUs} component={AboutUs} />
          <Route exact path={AppRoute.textToSpeech} component={TextToSpeechContainer} />
        </MainTemplate>
      </Route>

      <Route exact path={[AppRoute.avuser]}>
        <AVUserAuthorizedRoute exact path={AppRoute.avuser} component={AvUserRoot} />
      </Route>

      <Route exact path={AppRoute.videoPreview} component={VideoPreview} />
      {/* <Route extra path={AppRoute.studentNameListener} component={studentNameListener} /> */}
      <Route extra path={AppRoute.studentNameListenerEdit} component={StudentNameListenerEdit} />
      <Route extra path={AppRoute.studentLoginPage} component={StudentLoginPage} />
      <Route extra path={AppRoute.selfRegistration} component={SelfRegistrationContainer} />
      {/* <Route extra path={AppRoute.studentNameListener} component={StudentNameListener} /> */}

      <Redirect to={AppRoute.somethingWentWrong} />
    </Switch>
  );
};
